body,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: sans-serif;
}

.mainContainer {
	padding-top: 80px;
	padding-left: 80px;
	padding-right: 20px;
	padding-bottom: 20px;
}

@media screen and (max-width: 440px) {
	.mainContainer {
		padding-top: 70px;
		padding-left: 70px;
		padding-right: 10px;
		padding-bottom: 40px;
	}
}

.hideMe {
	opacity: 0;
	transition: height 1s, opacity 1s;
}

.showMe {
	opacity: 1;
	transition: height 1s, opacity 1s;
}

.gone {
	display: none !important;
}

.loader {
	/* position: absolute; */
	z-index: 10000;
}

.noScroll {
	max-height: 100vh !important;
}

#select_container {
	height: 30px;
}

a,
select {
	cursor: pointer;
}

.fG30 {
	gap: 30px;
}
.fG20 {
	gap: 20px;
}
.fG10 {
	gap: 10px;
}
.fG5 {
	gap: 5px;
}
.fG3 {
	gap: 3px;
}
.fG1 {
	gap: 1px;
}

.sideNumbersHeader,
.rangeTotals {
	font-size: 25px !important;
	font-weight: bold !important;
}
.sideNumbers {
	font-size: 17px !important;
}

div.watermark {
	background-image: url("../resources/Guillotine@3x.png");
	background-repeat: no-repeat;
	background-size: contain;
	opacity: 0.15;
	float: right;
	position: fixed;
	width: 62px;
	height: 88px;
	right: 10px;
	bottom: 10px;
	z-index: -1;
}

#stopit {
	color: red;
}

.main_footer {
	position: fixed;
	width: 100%;
	height: 100px;
	background-color: darkred;
	padding: 5px;
	bottom: 0px;
}

.common_button {
	cursor: pointer;
	border: 4px solid transparent !important;
}

.common_button:hover {
	cursor: pointer;
	border: 4px solid yellow !important;
}

.accountDropdown {
	background: black;
}

.accountDropdown option {
	background: dimgray;
	padding: 0px 2px;
}

/* Scrollbar */
body::-webkit-scrollbar {
	width: 24px;
}
body::-webkit-scrollbar-track {
	background: black;
}
body::-webkit-scrollbar-thumb {
	background: linear-gradient(
		200deg,
		#333 0%,
		#000 25%,
		#000 80%,
		red 98.9%,
		#000 99%
	);
}
/* ::-webkit-scrollbar-thumb:hover {
  background: darkorange;
} */

.number1 {
	font-size: 40px;
}

.number2 {
	margin: 0px;
	font-size: 30px;
}

.stockTicker {
	font-size: 27px;
}

.stockData {
	font-size: 20px;
}

.db {
	border: 1px solid red !important;
}

.percent1 {
	font-size: 20px;
}

.shadow {
	box-shadow: 0px 15px 40px rgba(0, 0, 0, 0.15);
}

.rainbow-bg {
	background: linear-gradient(
		124deg,
		#f96047,
		#f14848,
		#e8b71d,
		#e3e81d,
		#1de840,
		#1ddde8,
		#9c96f9,
		#eb5df9,
		#dd00f3
	);
	background-size: 1800% 1800%;

	-webkit-animation: rainbow 18s linear infinite;
	-z-animation: rainbow 18s linear infinite;
	-o-animation: rainbow 18s linear infinite;
	animation: rainbow 18s linear infinite;
}

@-webkit-keyframes rainbow {
	0% {
		background-position: 0% 82%;
	}
	50% {
		background-position: 100% 19%;
	}
	100% {
		background-position: 0% 82%;
	}
}
@-moz-keyframes rainbow {
	0% {
		background-position: 0% 82%;
	}
	50% {
		background-position: 100% 19%;
	}
	100% {
		background-position: 0% 82%;
	}
}
@-o-keyframes rainbow {
	0% {
		background-position: 0% 82%;
	}
	50% {
		background-position: 100% 19%;
	}
	100% {
		background-position: 0% 82%;
	}
}
@keyframes rainbow {
	0% {
		background-position: 0% 82%;
	}
	50% {
		background-position: 100% 19%;
	}
	100% {
		background-position: 0% 82%;
	}
}
